<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-5 col-12">Gram Panchayats</div>
        <div class="
                        col-lg-7 col-12
                        text-end
                        d-flex
                        justify-content-end
                        custom-flex-cloumn-mob
                      ">
        </div>
    </div>
    <div class="custom-ultima-datatable">
            <DataTable :value="grampanchayatsList" :scrollable="true" scrollHeight="flex" columnResizeMode="fit" paginator :totalRecords="totalRecords" :rows="30" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            :loading="loading" >
            <template #empty>No Records found.</template>
            <template #loading>Loading data. Please wait...</template>
            <Column field="id" header="Sr." headerStyle="width: 5%" bodyStyle="width: 5%">
                <template #body="{ data }">
                    {{ data.id + 1 }}
                </template>
            </Column>
            <Column field="Name" header="Gram Panchayats Name" headerStyle="width: 35%" bodyStyle="width: 35%">
                <template #body="{ data }">
                    <div>{{ data.gramlabel ? data.gramlabel : "N/A" }}</div>
                </template>
            </Column>
            <Column field="Name" header="Talukas Name" headerStyle="width: 30%" bodyStyle="width: 30%">
                <template #body="{ data }">
                    <div>{{ data.talulabel ? data.talulabel : "N/A" }}</div>
                </template>
            </Column>
            <Column field="Name" header="District Name" headerStyle="width: 30%" bodyStyle="width: 30%">
                <template #body="{ data }">
                    <div>{{ data.distlabel ? data.distlabel : "N/A" }}</div>
                </template>
            </Column>
        </DataTable>
    </div>
</template>
    <script>

export default {
    data() {
        return {
            grampanchayatsList: [
                {
                    id: 0,
                    gramlabel: "Village Panchayat Agonda",
                    talulabel: "Canacona",
                    distlabel: "South Goa",

                },
                {
                    id: 1,
                    gramlabel: "Village Panchayat Cola",
                    talulabel: "Canacona",
                    distlabel: "South Goa",
                },
                {
                    id: 2,
                    gramlabel: "Village Panchayat Cotigao",
                    talulabel: "Canacona",
                    distlabel: "South Goa",
                },
                {
                    id: 3,
                    gramlabel: "Village Panchayat Gaondongrem",
                    talulabel: "Canacona",
                    distlabel: "South Goa",
                },
                {
                    id: 4,
                    gramlabel: "Village Panchayat Loliem-Polem",
                    talulabel: "Canacona",
                    distlabel: "South Goa",
                },
                
            ],
            totalRecords: 5,
        };
    },
};
</script>